import { css } from '@gf/cross-platform-lib/styled-components';

interface ColumnProps {
  columns: number;
}

export const styles = {
  list: css`
    overflow: visible;
    width: 100%;
  `,
  row: css<ColumnProps>`
    flex-direction: row;
    justify-content: flex-start;
    gap: 24px;
    margin-bottom: ${({ columns }) => (columns === 1 ? '0px' : '16px')};
    width: 100%;
  `,
  itemContainer: css<ColumnProps>`
    flex-basis: ${({ columns }) =>
      columns === 3 ? 'calc(33.333% - 12px)' : columns === 2 ? 'calc(50% - 24px)' : '100%'};
    max-width: ${({ columns }) =>
      columns === 3 ? 'calc(33.333% - 12px)' : columns === 2 ? 'calc(50% - 24px)' : '100%'};
    box-sizing: border-box;
    min-width: 0;

    align-items: center;
  `
};
