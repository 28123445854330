import { useQueries, useQuery } from '@tanstack/react-query';
import { getAllTeams, getTeam } from '../apis/teamApis';
import { buildQueryKey } from '../utils/buildQueryKey';
import { StorableType } from '@gf/cross-platform-lib/utils/config/config';
import { IPagination } from '@gf/cross-platform-lib/interfaces/IPaginationRequest';

export const useGetAllTeams = (
  organizationId: string,
  pagination: IPagination = {
    page: 0,
    size: 50
  }
) => {
  return useQuery({
    queryKey: buildQueryKey([StorableType.TeamSearchResults, organizationId]),
    queryFn: () => getAllTeams(organizationId, pagination),
    staleTime: 1000 * 60,
    enabled: !!organizationId
  }).data?.data?.content;
};

export const useGetTeamsByTeamIds = (teamIds: string[]) => {
  return useQueries({
    queries: teamIds.map(id => {
      return {
        queryKey: buildQueryKey([StorableType.TeamSearchResults, id]),
        queryFn: () => getTeam(id),
        staleTime: 1000 * 60,
        enabled: !!id
      };
    })
  }).filter(resp => !resp.isFetching && resp.data);
};

export const useGetAllTeamsBySchoolIds = (
  organizationIds: string[],
  pagination: IPagination = {
    page: 0,
    size: 50
  }
) => {
  return useQueries({
    queries: organizationIds.map(id => {
      return {
        queryKey: buildQueryKey([StorableType.TeamSearchResults, id]),
        queryFn: () => getAllTeams(id, pagination),
        staleTime: 1000 * 60,
        enabled: !!id
      };
    })
  }).filter(resp => !resp.isFetching && resp.data);
};
